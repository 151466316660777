var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getISOLocalDateTime = exports.getISOLocalDate = exports.getISOLocalMonth = exports.getHoursMinutesSeconds = exports.getHoursMinutes = exports.getDaysInMonth = exports.getDayRange = exports.getNextDayEnd = exports.getPreviousDayEnd = exports.getDayEnd = exports.getNextDayStart = exports.getPreviousDayStart = exports.getDayStart = exports.getMonthRange = exports.getNextMonthEnd = exports.getPreviousMonthEnd = exports.getMonthEnd = exports.getNextMonthStart = exports.getPreviousMonthStart = exports.getMonthStart = exports.getYearRange = exports.getNextYearEnd = exports.getPreviousYearEnd = exports.getYearEnd = exports.getNextYearStart = exports.getPreviousYearStart = exports.getYearStart = exports.getDecadeRange = exports.getNextDecadeEnd = exports.getPreviousDecadeEnd = exports.getDecadeEnd = exports.getNextDecadeStart = exports.getPreviousDecadeStart = exports.getDecadeStart = exports.getCenturyRange = exports.getNextCenturyEnd = exports.getPreviousCenturyEnd = exports.getCenturyEnd = exports.getNextCenturyStart = exports.getPreviousCenturyStart = exports.getCenturyStart = exports.getMilliseconds = exports.getSeconds = exports.getMinutes = exports.getHours = exports.getDate = exports.getMonthHuman = exports.getMonth = exports.getYear = void 0;
/**
 * Utils
 */
function makeGetEdgeOfNeighbor(getPeriod, getEdgeOfPeriod, defaultOffset) {
  return function makeGetEdgeOfNeighborInternal(date, offset) {
    if (offset === void 0) {
      offset = defaultOffset;
    }
    var previousPeriod = getPeriod(date) + offset;
    return getEdgeOfPeriod(previousPeriod);
  };
}
function makeGetEnd(getBeginOfNextPeriod) {
  return function makeGetEndInternal(date) {
    return new Date(getBeginOfNextPeriod(date).getTime() - 1);
  };
}
function makeGetRange(getStart, getEnd) {
  return function makeGetRangeInternal(date) {
    return [getStart(date), getEnd(date)];
  };
}
/**
 * Simple getters - getting a property of a given point in time
 */
/**
 * Gets year from a given date.
 *
 * @param {DateLike} date Date to get year from
 * @returns {number} Year
 */
function getYear(date) {
  if (date instanceof Date) {
    return date.getFullYear();
  }
  if (typeof date === "number") {
    return date;
  }
  var year = parseInt(date, 10);
  if (typeof date === "string" && !isNaN(year)) {
    return year;
  }
  throw new Error("Failed to get year from date: ".concat(date, "."));
}
exports.getYear = getYear;
/**
 * Gets month from a given date.
 *
 * @param {Date} date Date to get month from
 * @returns {number} Month
 */
function getMonth(date) {
  if (date instanceof Date) {
    return date.getMonth();
  }
  throw new Error("Failed to get month from date: ".concat(date, "."));
}
exports.getMonth = getMonth;
/**
 * Gets human-readable month from a given date.
 *
 * @param {Date} date Date to get human-readable month from
 * @returns {number} Human-readable month
 */
function getMonthHuman(date) {
  if (date instanceof Date) {
    return date.getMonth() + 1;
  }
  throw new Error("Failed to get human-readable month from date: ".concat(date, "."));
}
exports.getMonthHuman = getMonthHuman;
/**
 * Gets day of the month from a given date.
 *
 * @param {Date} date Date to get day of the month from
 * @returns {number} Day of the month
 */
function getDate(date) {
  if (date instanceof Date) {
    return date.getDate();
  }
  throw new Error("Failed to get year from date: ".concat(date, "."));
}
exports.getDate = getDate;
/**
 * Gets hours from a given date.
 *
 * @param {Date | string} date Date to get hours from
 * @returns {number} Hours
 */
function getHours(date) {
  if (date instanceof Date) {
    return date.getHours();
  }
  if (typeof date === "string") {
    var datePieces = date.split(":");
    if (datePieces.length >= 2) {
      var hoursString = datePieces[0];
      if (hoursString) {
        var hours = parseInt(hoursString, 10);
        if (!isNaN(hours)) {
          return hours;
        }
      }
    }
  }
  throw new Error("Failed to get hours from date: ".concat(date, "."));
}
exports.getHours = getHours;
/**
 * Gets minutes from a given date.
 *
 * @param {Date | string} date Date to get minutes from
 * @returns {number} Minutes
 */
function getMinutes(date) {
  if (date instanceof Date) {
    return date.getMinutes();
  }
  if (typeof date === "string") {
    var datePieces = date.split(":");
    if (datePieces.length >= 2) {
      var minutesString = datePieces[1] || "0";
      var minutes = parseInt(minutesString, 10);
      if (!isNaN(minutes)) {
        return minutes;
      }
    }
  }
  throw new Error("Failed to get minutes from date: ".concat(date, "."));
}
exports.getMinutes = getMinutes;
/**
 * Gets seconds from a given date.
 *
 * @param {Date | string} date Date to get seconds from
 * @returns {number} Seconds
 */
function getSeconds(date) {
  if (date instanceof Date) {
    return date.getSeconds();
  }
  if (typeof date === "string") {
    var datePieces = date.split(":");
    if (datePieces.length >= 2) {
      var secondsWithMillisecondsString = datePieces[2] || "0";
      var seconds = parseInt(secondsWithMillisecondsString, 10);
      if (!isNaN(seconds)) {
        return seconds;
      }
    }
  }
  throw new Error("Failed to get seconds from date: ".concat(date, "."));
}
exports.getSeconds = getSeconds;
/**
 * Gets milliseconds from a given date.
 *
 * @param {Date | string} date Date to get milliseconds from
 * @returns {number} Milliseconds
 */
function getMilliseconds(date) {
  if (date instanceof Date) {
    return date.getMilliseconds();
  }
  if (typeof date === "string") {
    var datePieces = date.split(":");
    if (datePieces.length >= 2) {
      var secondsWithMillisecondsString = datePieces[2] || "0";
      var millisecondsString = secondsWithMillisecondsString.split(".")[1] || "0";
      var milliseconds = parseInt(millisecondsString, 10);
      if (!isNaN(milliseconds)) {
        return milliseconds;
      }
    }
  }
  throw new Error("Failed to get seconds from date: ".concat(date, "."));
}
exports.getMilliseconds = getMilliseconds;
/**
 * Century
 */
/**
 * Gets century start date from a given date.
 *
 * @param {DateLike} date Date to get century start from
 * @returns {Date} Century start date
 */
function getCenturyStart(date) {
  var year = getYear(date);
  var centuryStartYear = year + (-year + 1) % 100;
  var centuryStartDate = new Date();
  centuryStartDate.setFullYear(centuryStartYear, 0, 1);
  centuryStartDate.setHours(0, 0, 0, 0);
  return centuryStartDate;
}
exports.getCenturyStart = getCenturyStart;
/**
 * Gets previous century start date from a given date.
 *
 * @param {DateLike} date Date to get previous century start from
 * @returns {Date} Previous century start date
 */
exports.getPreviousCenturyStart = makeGetEdgeOfNeighbor(getYear, getCenturyStart, -100);
/**
 * Gets next century start date from a given date.
 *
 * @param {DateLike} date Date to get next century start from
 * @returns {Date} Next century start date
 */
exports.getNextCenturyStart = makeGetEdgeOfNeighbor(getYear, getCenturyStart, 100);
/**
 * Gets century end date from a given date.
 *
 * @param {DateLike} date Date to get century end from
 * @returns {Date} Century end date
 */
exports.getCenturyEnd = makeGetEnd(exports.getNextCenturyStart);
/**
 * Gets previous century end date from a given date.
 *
 * @param {DateLike} date Date to get previous century end from
 * @returns {Date} Previous century end date
 */
exports.getPreviousCenturyEnd = makeGetEdgeOfNeighbor(getYear, exports.getCenturyEnd, -100);
/**
 * Gets next century end date from a given date.
 *
 * @param {DateLike} date Date to get next century end from
 * @returns {Date} Next century end date
 */
exports.getNextCenturyEnd = makeGetEdgeOfNeighbor(getYear, exports.getCenturyEnd, 100);
/**
 * Gets century start and end dates from a given date.
 *
 * @param {DateLike} date Date to get century start and end from
 * @returns {[Date, Date]} Century start and end dates
 */
exports.getCenturyRange = makeGetRange(getCenturyStart, exports.getCenturyEnd);
/**
 * Decade
 */
/**
 * Gets decade start date from a given date.
 *
 * @param {DateLike} date Date to get decade start from
 * @returns {Date} Decade start date
 */
function getDecadeStart(date) {
  var year = getYear(date);
  var decadeStartYear = year + (-year + 1) % 10;
  var decadeStartDate = new Date();
  decadeStartDate.setFullYear(decadeStartYear, 0, 1);
  decadeStartDate.setHours(0, 0, 0, 0);
  return decadeStartDate;
}
exports.getDecadeStart = getDecadeStart;
/**
 * Gets previous decade start date from a given date.
 *
 * @param {DateLike} date Date to get previous decade start from
 * @returns {Date} Previous decade start date
 */
exports.getPreviousDecadeStart = makeGetEdgeOfNeighbor(getYear, getDecadeStart, -10);
/**
 * Gets next decade start date from a given date.
 *
 * @param {DateLike} date Date to get next decade start from
 * @returns {Date} Next decade start date
 */
exports.getNextDecadeStart = makeGetEdgeOfNeighbor(getYear, getDecadeStart, 10);
/**
 * Gets decade end date from a given date.
 *
 * @param {DateLike} date Date to get decade end from
 * @returns {Date} Decade end date
 */
exports.getDecadeEnd = makeGetEnd(exports.getNextDecadeStart);
/**
 * Gets previous decade end date from a given date.
 *
 * @param {DateLike} date Date to get previous decade end from
 * @returns {Date} Previous decade end date
 */
exports.getPreviousDecadeEnd = makeGetEdgeOfNeighbor(getYear, exports.getDecadeEnd, -10);
/**
 * Gets next decade end date from a given date.
 *
 * @param {DateLike} date Date to get next decade end from
 * @returns {Date} Next decade end date
 */
exports.getNextDecadeEnd = makeGetEdgeOfNeighbor(getYear, exports.getDecadeEnd, 10);
/**
 * Gets decade start and end dates from a given date.
 *
 * @param {DateLike} date Date to get decade start and end from
 * @returns {[Date, Date]} Decade start and end dates
 */
exports.getDecadeRange = makeGetRange(getDecadeStart, exports.getDecadeEnd);
/**
 * Year
 */
/**
 * Gets year start date from a given date.
 *
 * @param {DateLike} date Date to get year start from
 * @returns {Date} Year start date
 */
function getYearStart(date) {
  var year = getYear(date);
  var yearStartDate = new Date();
  yearStartDate.setFullYear(year, 0, 1);
  yearStartDate.setHours(0, 0, 0, 0);
  return yearStartDate;
}
exports.getYearStart = getYearStart;
/**
 * Gets previous year start date from a given date.
 *
 * @param {DateLike} date Date to get previous year start from
 * @returns {Date} Previous year start date
 */
exports.getPreviousYearStart = makeGetEdgeOfNeighbor(getYear, getYearStart, -1);
/**
 * Gets next year start date from a given date.
 *
 * @param {DateLike} date Date to get next year start from
 * @returns {Date} Next year start date
 */
exports.getNextYearStart = makeGetEdgeOfNeighbor(getYear, getYearStart, 1);
/**
 * Gets year end date from a given date.
 *
 * @param {DateLike} date Date to get year end from
 * @returns {Date} Year end date
 */
exports.getYearEnd = makeGetEnd(exports.getNextYearStart);
/**
 * Gets previous year end date from a given date.
 *
 * @param {DateLike} date Date to get previous year end from
 * @returns {Date} Previous year end date
 */
exports.getPreviousYearEnd = makeGetEdgeOfNeighbor(getYear, exports.getYearEnd, -1);
/**
 * Gets next year end date from a given date.
 *
 * @param {DateLike} date Date to get next year end from
 * @returns {Date} Next year end date
 */
exports.getNextYearEnd = makeGetEdgeOfNeighbor(getYear, exports.getYearEnd, 1);
/**
 * Gets year start and end dates from a given date.
 *
 * @param {DateLike} date Date to get year start and end from
 * @returns {[Date, Date]} Year start and end dates
 */
exports.getYearRange = makeGetRange(getYearStart, exports.getYearEnd);
/**
 * Month
 */
function makeGetEdgeOfNeighborMonth(getEdgeOfPeriod, defaultOffset) {
  return function makeGetEdgeOfNeighborMonthInternal(date, offset) {
    if (offset === void 0) {
      offset = defaultOffset;
    }
    var year = getYear(date);
    var month = getMonth(date) + offset;
    var previousPeriod = new Date();
    previousPeriod.setFullYear(year, month, 1);
    previousPeriod.setHours(0, 0, 0, 0);
    return getEdgeOfPeriod(previousPeriod);
  };
}
/**
 * Gets month start date from a given date.
 *
 * @param {DateLike} date Date to get month start from
 * @returns {Date} Month start date
 */
function getMonthStart(date) {
  var year = getYear(date);
  var month = getMonth(date);
  var monthStartDate = new Date();
  monthStartDate.setFullYear(year, month, 1);
  monthStartDate.setHours(0, 0, 0, 0);
  return monthStartDate;
}
exports.getMonthStart = getMonthStart;
/**
 * Gets previous month start date from a given date.
 *
 * @param {DateLike} date Date to get previous month start from
 * @returns {Date} Previous month start date
 */
exports.getPreviousMonthStart = makeGetEdgeOfNeighborMonth(getMonthStart, -1);
/**
 * Gets next month start date from a given date.
 *
 * @param {DateLike} date Date to get next month start from
 * @returns {Date} Next month start date
 */
exports.getNextMonthStart = makeGetEdgeOfNeighborMonth(getMonthStart, 1);
/**
 * Gets month end date from a given date.
 *
 * @param {DateLike} date Date to get month end from
 * @returns {Date} Month end date
 */
exports.getMonthEnd = makeGetEnd(exports.getNextMonthStart);
/**
 * Gets previous month end date from a given date.
 *
 * @param {DateLike} date Date to get previous month end from
 * @returns {Date} Previous month end date
 */
exports.getPreviousMonthEnd = makeGetEdgeOfNeighborMonth(exports.getMonthEnd, -1);
/**
 * Gets next month end date from a given date.
 *
 * @param {DateLike} date Date to get next month end from
 * @returns {Date} Next month end date
 */
exports.getNextMonthEnd = makeGetEdgeOfNeighborMonth(exports.getMonthEnd, 1);
/**
 * Gets month start and end dates from a given date.
 *
 * @param {DateLike} date Date to get month start and end from
 * @returns {[Date, Date]} Month start and end dates
 */
exports.getMonthRange = makeGetRange(getMonthStart, exports.getMonthEnd);
/**
 * Day
 */
function makeGetEdgeOfNeighborDay(getEdgeOfPeriod, defaultOffset) {
  return function makeGetEdgeOfNeighborDayInternal(date, offset) {
    if (offset === void 0) {
      offset = defaultOffset;
    }
    var year = getYear(date);
    var month = getMonth(date);
    var day = getDate(date) + offset;
    var previousPeriod = new Date();
    previousPeriod.setFullYear(year, month, day);
    previousPeriod.setHours(0, 0, 0, 0);
    return getEdgeOfPeriod(previousPeriod);
  };
}
/**
 * Gets day start date from a given date.
 *
 * @param {DateLike} date Date to get day start from
 * @returns {Date} Day start date
 */
function getDayStart(date) {
  var year = getYear(date);
  var month = getMonth(date);
  var day = getDate(date);
  var dayStartDate = new Date();
  dayStartDate.setFullYear(year, month, day);
  dayStartDate.setHours(0, 0, 0, 0);
  return dayStartDate;
}
exports.getDayStart = getDayStart;
/**
 * Gets previous day start date from a given date.
 *
 * @param {DateLike} date Date to get previous day start from
 * @returns {Date} Previous day start date
 */
exports.getPreviousDayStart = makeGetEdgeOfNeighborDay(getDayStart, -1);
/**
 * Gets next day start date from a given date.
 *
 * @param {DateLike} date Date to get next day start from
 * @returns {Date} Next day start date
 */
exports.getNextDayStart = makeGetEdgeOfNeighborDay(getDayStart, 1);
/**
 * Gets day end date from a given date.
 *
 * @param {DateLike} date Date to get day end from
 * @returns {Date} Day end date
 */
exports.getDayEnd = makeGetEnd(exports.getNextDayStart);
/**
 * Gets previous day end date from a given date.
 *
 * @param {DateLike} date Date to get previous day end from
 * @returns {Date} Previous day end date
 */
exports.getPreviousDayEnd = makeGetEdgeOfNeighborDay(exports.getDayEnd, -1);
/**
 * Gets next day end date from a given date.
 *
 * @param {DateLike} date Date to get next day end from
 * @returns {Date} Next day end date
 */
exports.getNextDayEnd = makeGetEdgeOfNeighborDay(exports.getDayEnd, 1);
/**
 * Gets day start and end dates from a given date.
 *
 * @param {DateLike} date Date to get day start and end from
 * @returns {[Date, Date]} Day start and end dates
 */
exports.getDayRange = makeGetRange(getDayStart, exports.getDayEnd);
/**
 * Other
 */
/**
 * Returns a number of days in a month of a given date.
 *
 * @param {Date} date Date
 * @returns {number} Number of days in a month
 */
function getDaysInMonth(date) {
  return getDate((0, exports.getMonthEnd)(date));
}
exports.getDaysInMonth = getDaysInMonth;
function padStart(num, val) {
  if (val === void 0) {
    val = 2;
  }
  var numStr = "".concat(num);
  if (numStr.length >= val) {
    return num;
  }
  return "0000".concat(numStr).slice(-val);
}
/**
 * Returns local hours and minutes (hh:mm).
 *
 * @param {Date | string} date Date to get hours and minutes from
 * @returns {string} Local hours and minutes
 */
function getHoursMinutes(date) {
  var hours = padStart(getHours(date));
  var minutes = padStart(getMinutes(date));
  return "".concat(hours, ":").concat(minutes);
}
exports.getHoursMinutes = getHoursMinutes;
/**
 * Returns local hours, minutes and seconds (hh:mm:ss).
 *
 * @param {Date | string} date Date to get hours, minutes and seconds from
 * @returns {string} Local hours, minutes and seconds
 */
function getHoursMinutesSeconds(date) {
  var hours = padStart(getHours(date));
  var minutes = padStart(getMinutes(date));
  var seconds = padStart(getSeconds(date));
  return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
}
exports.getHoursMinutesSeconds = getHoursMinutesSeconds;
/**
 * Returns local month in ISO-like format (YYYY-MM).
 *
 * @param {Date} date Date to get month in ISO-like format from
 * @returns {string} Local month in ISO-like format
 */
function getISOLocalMonth(date) {
  var year = padStart(getYear(date), 4);
  var month = padStart(getMonthHuman(date));
  return "".concat(year, "-").concat(month);
}
exports.getISOLocalMonth = getISOLocalMonth;
/**
 * Returns local date in ISO-like format (YYYY-MM-DD).
 *
 * @param {Date} date Date to get date in ISO-like format from
 * @returns {string} Local date in ISO-like format
 */
function getISOLocalDate(date) {
  var year = padStart(getYear(date), 4);
  var month = padStart(getMonthHuman(date));
  var day = padStart(getDate(date));
  return "".concat(year, "-").concat(month, "-").concat(day);
}
exports.getISOLocalDate = getISOLocalDate;
/**
 * Returns local date & time in ISO-like format (YYYY-MM-DDThh:mm:ss).
 *
 * @param {Date} date Date to get date & time in ISO-like format from
 * @returns {string} Local date & time in ISO-like format
 */
function getISOLocalDateTime(date) {
  return "".concat(getISOLocalDate(date), "T").concat(getHoursMinutesSeconds(date));
}
exports.getISOLocalDateTime = getISOLocalDateTime;
export default exports;
export const __esModule = exports.__esModule,
  getDayRange = exports.getDayRange,
  getNextDayEnd = exports.getNextDayEnd,
  getPreviousDayEnd = exports.getPreviousDayEnd,
  getDayEnd = exports.getDayEnd,
  getNextDayStart = exports.getNextDayStart,
  getPreviousDayStart = exports.getPreviousDayStart,
  getMonthRange = exports.getMonthRange,
  getNextMonthEnd = exports.getNextMonthEnd,
  getPreviousMonthEnd = exports.getPreviousMonthEnd,
  getMonthEnd = exports.getMonthEnd,
  getNextMonthStart = exports.getNextMonthStart,
  getPreviousMonthStart = exports.getPreviousMonthStart,
  getYearRange = exports.getYearRange,
  getNextYearEnd = exports.getNextYearEnd,
  getPreviousYearEnd = exports.getPreviousYearEnd,
  getYearEnd = exports.getYearEnd,
  getNextYearStart = exports.getNextYearStart,
  getPreviousYearStart = exports.getPreviousYearStart,
  getDecadeRange = exports.getDecadeRange,
  getNextDecadeEnd = exports.getNextDecadeEnd,
  getPreviousDecadeEnd = exports.getPreviousDecadeEnd,
  getDecadeEnd = exports.getDecadeEnd,
  getNextDecadeStart = exports.getNextDecadeStart,
  getPreviousDecadeStart = exports.getPreviousDecadeStart,
  getCenturyRange = exports.getCenturyRange,
  getNextCenturyEnd = exports.getNextCenturyEnd,
  getPreviousCenturyEnd = exports.getPreviousCenturyEnd,
  getCenturyEnd = exports.getCenturyEnd,
  getNextCenturyStart = exports.getNextCenturyStart,
  getPreviousCenturyStart = exports.getPreviousCenturyStart;
const _getISOLocalDateTime = exports.getISOLocalDateTime,
  _getISOLocalDate = exports.getISOLocalDate,
  _getISOLocalMonth = exports.getISOLocalMonth,
  _getHoursMinutesSeconds = exports.getHoursMinutesSeconds,
  _getHoursMinutes = exports.getHoursMinutes,
  _getDaysInMonth = exports.getDaysInMonth,
  _getDayStart = exports.getDayStart,
  _getMonthStart = exports.getMonthStart,
  _getYearStart = exports.getYearStart,
  _getDecadeStart = exports.getDecadeStart,
  _getCenturyStart = exports.getCenturyStart,
  _getMilliseconds = exports.getMilliseconds,
  _getSeconds = exports.getSeconds,
  _getMinutes = exports.getMinutes,
  _getHours = exports.getHours,
  _getDate = exports.getDate,
  _getMonthHuman = exports.getMonthHuman,
  _getMonth = exports.getMonth,
  _getYear = exports.getYear;
export { _getISOLocalDateTime as getISOLocalDateTime, _getISOLocalDate as getISOLocalDate, _getISOLocalMonth as getISOLocalMonth, _getHoursMinutesSeconds as getHoursMinutesSeconds, _getHoursMinutes as getHoursMinutes, _getDaysInMonth as getDaysInMonth, _getDayStart as getDayStart, _getMonthStart as getMonthStart, _getYearStart as getYearStart, _getDecadeStart as getDecadeStart, _getCenturyStart as getCenturyStart, _getMilliseconds as getMilliseconds, _getSeconds as getSeconds, _getMinutes as getMinutes, _getHours as getHours, _getDate as getDate, _getMonthHuman as getMonthHuman, _getMonth as getMonth, _getYear as getYear };